<template>
    <div class="adver_container">
      <div class="footer">
        <div class="copy">版权所有Copyright &copy智慧分析</div>
        <div><a style="text-decoration: none;color: white;" href="https://beian.miit.gov.cn/"
            target="_blank">已备案:粤ICP备2021054626号</a></div>
      </div>
      <div class="adver_box">
        <!-- <div class="adver_box">
         <img src="../assets/logo.png" alt>
        </div>-->
        <div class="title">欢迎使用智慧分析</div>
  
        <div class="link">
          <router-link class="login" to="/main">开始使用</router-link>
        </div>
      </div>
  
    </div>
  </template>
  
  <script>
    export default {
      data() {
        
      },
      mounted() {
        
      },
      methods: {
        
        },
    };
  </script>
  
  <style lang="less" scoped>
    .footer {
      width: 100%;
      text-align: center;
      position: fixed;
      bottom: 80px;
      color: white;
    }
  
    .copy {
      margin-bottom: 10px;
    }
  
    .adver_container {
      width: 100%;
      background-color: #373d41;
      height: 100%;
      // font-size:14px;
    }
    .adver_box {
    width: 450px;
    height: 300px;
    background: #373d41;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    }
      .title {
        width: 100%;
        height: 30px;
        position: absolute;
        text-align: center;
        top: 90px;
        font-size: 30px;
        color: #f1f5f8;
      }
  
  
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background-color: #eee;
        }
  
      .link {
        width: 100%;
        height: 30px;
        position: absolute;
        text-align: center;
        top: 90px;
        .login {
        width: 100%;
        height: 30px;
        position: absolute;
        text-align: center;
        top: 90px;
        left:110px;
        color: #f2f7fa;
        }
    }

    .btns {
      text-align: center;
    }
  </style>
  